import React from 'react';
import { When } from 'react-if';

import { classNames }  from '../lib/classNames';

import { Heading } from './Typography/Heading';
import { Paragraph } from './Typography/Paragraph';

export interface DescriptionItem {
  title: string
  subText?: string
  content?: string | React.ReactNode
  child?: React.ReactNode
}

interface IDescriptionListProps {
  items: DescriptionItem[]
  title?: string
  description?: string
  /** Override the entire header */
  header?: React.ReactNode
  className?: string
}

export function DescriptionList({ items, title, description, className, header }: IDescriptionListProps) {
  return (
    <div className={
      classNames(
        `overflow-hidden bg-white shadow sm:rounded-lg `,
        className,
      )
    }>
      <When condition={ (title || description) && !header  }>
        <div className={ `px-4 py-5 sm:px-6` }>
          <Heading>{ title }</Heading>
          <Paragraph
            variant={ `secondary` }
            className={ `mt-1 max-w-2xl` }
            as={ `div` }
          >
            { description }
          </Paragraph>
        </div>
      </When>

      <When condition={ !!header }>
        { header }
      </When>
      <div className={
        classNames(
          `border-gray-200 px-4 py-5 sm:p-0`,
          (title || description || !!header) ? `border-t` : ``,
        )
      }>
        <dl className={ `sm:divide-y sm:divide-gray-200` }>
          {
            items.map((item, i) => {
              return (
                <div
                  key={ i }
                  className={
                    classNames(
                      `py-4 sm:grid sm:gap-4 sm:py-5 sm:px-6`,
                      item.child ? `sm:grid-cols-3` : `sm:grid-cols-2`, // Remove child col when not used
                    )
                  }
                >
                  <div>
                    <Heading
                      size={ `sm` }
                      className={ `self-center` }
                    >
                      { item.title }
                    </Heading>
                    <When condition={ item.subText }>
                      <Paragraph variant={ `help` }>
                        { item.subText }
                      </Paragraph>
                    </When>
                  </div>
                  <Paragraph
                    className={ `mt-1 sm:col-span-1 sm:mt-0` }
                    variant={ `secondary` }
                    as={ `div` }
                  >
                    { item.content }
                  </Paragraph>
                  <When condition={ !!item.child }>
                    <dd className={ `sm:col-span-1 justify-self-end` }>
                      { item.child }
                    </dd>
                  </When>
                </div>
              );
            })
          }
        </dl>
      </div>
    </div>
  );
}
