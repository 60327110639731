import { useGetSelectedOrganisation } from './useGetSelectedOrganisation';

/** Returns plan permissions and usage */
export function usePlanDetails() {
  const organisation = useGetSelectedOrganisation();

  if (!organisation || !organisation.usage) {
    return null;
  }

  return organisation.usage;
}

