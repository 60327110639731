import { IPlanV3, plansV3 } from "shared";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { usePlans } from "../../hooks/usePlans";
import { SmallSpinner } from "../../../common/Atoms/SmallSpinner";

import { NewPlan } from "./NewPlan";

export function NewPlans() {
  const { isLoading } = usePlans();

  if (isLoading) return (
    <div className={ `flex justify-center pt-6` }>
      <SmallSpinner
        colored
        size={ `lg` }
      />
    </div>
  );

  return (
    <div>
      <div className={ `flex justify-center pt-6` }>
        <Grid2
          container
          columns={ 2 }
          spacing={ 3 }
        >
          {
            Object.keys(plansV3).map(key => {
              const product = plansV3[key] as IPlanV3;

              return (
                <NewPlan
                  key={ key }
                  plan={ product }
                />
              );
            })
          }
        </Grid2>
      </div>
      <div className={ `flex justify-center pt-1` }>
        <Paragraph
          className={ `text-center` }
          variant={ `help` }>
          { `All prices in USD. Price excludes any applicable taxes. Billed Monthly. Cancel Anytime.` }
        </Paragraph>
      </div>
      <div className={ `flex justify-center pt-1` }>
        <Paragraph
          className={ `text-center` }
          variant={ `help` }>
          { `*Invoices means number of invoices we needed to track. E.g Only unpaid invoices in most cases.` }
        </Paragraph>
      </div>
      <div className={ `flex justify-center pt-12` }>
        <div className={ `flex flex-col items-center` }>
          <Heading
            size={ `3xl` }
            variant={ `secondary` }
          >
            { `F.A.Qs` }
          </Heading>
          <div className={ `space-y-3` }>
            { /* <Heading>
              { `What is counted as an action?` }
            </Heading>
            <Paragraph>
              { `An action is when we issue a late fee, remove a discount or send an SMS message` }
            </Paragraph> */ }

            <Heading>
              { `What is counted an email?` }
            </Heading>
            <Paragraph>
              { `An email is when we send an auto-statement, reminder, late fee & discount notification emails` }
            </Paragraph>

            <Heading>
              { `What if I have more than one organisation?` }
            </Heading>
            <Paragraph>
              { `Multiple organisations can be added to any Pro plan to share the allowance for just $10 per month each. Please contact us if you wish to remove any add ons` }
            </Paragraph>

            <Heading>
              { `Is there also tax to pay on the subscription price?` }
            </Heading>
            <Paragraph>
              { `Paidnice will add any applicable taxes to your monthly subscription. Paidnice is required to collect New Zealand GST for any customer with their billing address in New Zealand. ` }
            </Paragraph>
          </div>

        </div>
      </div>
    </div>
  );
}
