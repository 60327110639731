import { When } from "react-if";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

import { CurrentStatement } from "../../../../../common/Components/Contact/CurrentStatement";
import { env } from "../../../../../common/lib/env";
import { useApiRequest } from "../../../../hooks/useApiRequest";
import { useContact } from "../../../../hooks/useContact";

export function ContactStatements() {
  const { data: contact, isFetching, isLoading, statementPeriod, currentCurrency } = useContact();
  const request = useApiRequest();

  async function onDownload(currency: string) {
    try {

      const result = await request({
        method: `GET`,
        responseType: `blob`,
        url: `${env.baseApiUrl}contact/${contact.id}/statement/download`,
        params: {
          period: statementPeriod,
          currency,
        },
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement(`a`);
      link.href = url;

      const fileNamePeriodEndSection = contact.extraData.statement.period.end ? `${contact.extraData.statement.period.end}` : ``;
      const fileNamePeriodStartSection = contact.extraData.statement.period.start ? `${contact.extraData.statement.period.start}` : ``;
      const divider = fileNamePeriodStartSection && fileNamePeriodEndSection ? `-` : ``;
      let fileNamePeriodSection = `${fileNamePeriodStartSection}${divider}${fileNamePeriodEndSection}`;

      if (!fileNamePeriodSection) {
        fileNamePeriodSection = DateTime.fromJSDate(new Date(contact.extraData.statement.date), { zone: contact.tz }).toFormat(`yyyy-MM-dd`);
      }

      link.setAttribute(`download`, `${contact.name}_${fileNamePeriodSection}_outstanding.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
    catch (e) {
      const responseObj = await e.response?.data?.text();
      toast.error(`${responseObj ? `${responseObj}` : `Could not create statement.`}`);
    }
  }

  const relevantStatement = contact?.extraData?.statement?.currencySections.find(section => section.currency === currentCurrency);

  if (!relevantStatement) {
    return null;
  }

  return (
    <When condition={ !!contact?.extraData?.statement }>
      {
        () => (
          <div className={ `space-y-4` }>
            <CurrentStatement
              currencySection={ relevantStatement }
              sectionCount={ Object.keys(contact.extraData.statement.currencySections).length }
              statementPeriod={ contact.extraData.statement.period }
              onDownload={ onDownload }
              loading={ isFetching || isLoading }
              organisationTimezone={ contact.extraData.statement?.organisationTimezone }
              omitColumns={ [ `payments` ] }
              childContacts = { contact.childContacts }
            />
          </div>
        )
      }
    </When>
  );
}
