import { IUpdateSettingsRequest } from "shared";
import { When } from "react-if";
import { useMemo } from "react";
import { DateTime } from "luxon";

import { useAllowedFeature } from "../../../../hooks/useAllowedFeature";
import { classNames } from "../../../../../common/lib/classNames";
import { Notification } from '../../../../../common/Atoms/Notification';
import { InputTextAddon } from "../../../../../common/Atoms/InputTextAddon";
import { WEBHOOKS_ARTICLE } from "../../../../constants/links";
import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import { UpgradeBadge } from "../../../Molecules/UpgradeBadge";
import { OnChangeFn } from "../Main/GeneralSettings";
import { useSettings } from "../../../../hooks/useSettings";
import { FormLabel } from "../../../../../common/Atoms/FormLabel";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";

interface WebhooksProps {
  formData: IUpdateSettingsRequest;
  onChange: OnChangeFn;
}

export function Webhooks({ formData, onChange }: WebhooksProps) {
  const allowedFeature = useAllowedFeature(`webhooks`);
  const { data: setting } = useSettings();

  const notification = useMemo(() => {
    if (formData.webhookEnabled === false && formData.webhookUrl) {
      return `There was a problem connecting to your webhook URL and it has been disabled, please set a new webhook URL to enable webhooks again.`;
    }

    return null;
  }, [ formData.webhookEnabled, formData.webhookUrl ]);

  function handleChange(e) {
    onChange(e.name, e.value);
  }

  const badge = !allowedFeature && (
    <UpgradeBadge />
  );

  return (
    <TwoColFormRow
      heading={ `Webhooks` }
      headingHelpLink={ WEBHOOKS_ARTICLE }
      badge={ badge }
      help={
        allowedFeature ?
          undefined :
          `Upgrade to "Pro" to enable webhooks.`
      }
    >
      <div className={
        classNames(
          `space-y-2`,
          allowedFeature ? `` : `opacity-50`,
        )
      }>
        <When condition={ !!notification }>
          <Notification
            type={ `warning` }
            title={ `Your webhook has been disabled` }
          >
            { notification }
          </Notification>
        </When>

        <InputTextAddon
          name={ `webhookUrl` }
          value={ formData.webhookUrl || `` }
          label={ `Webhook URL` }
          onChange={ handleChange }
          disabled={ !allowedFeature }
          description={ `The web address where Paidnice will send webhook notifications.` }
          prefix={ `POST` }
        />

        { /* TODO: Link to new action page using ID */ }

        <When condition={ setting?.recentWebhooks && setting.recentWebhooks.length > 0 }>
          { () => (
            <>
              <FormLabel>{ `Recent Requests` }</FormLabel>
              <div className={ `` }>
                {
                  setting.recentWebhooks.map((webhook, index) => (
                    <div
                      key={ index }
                      className={ `grid grid-cols-12 space-y-2 border-b border-gray-100 pb-1` }
                    >
                      <Paragraph
                        className={ `col-span-3 justify-self-start self-center` }
                        variant={ `note` }
                      >
                        { DateTime.fromJSDate(new Date(webhook.createdAt)).toFormat(`yyyy-LL-dd HH:mm`) }
                      </Paragraph>

                      <Paragraph
                        className={ `col-span-8 justify-self-start self-center` }
                        variant={ `secondary` }
                      >
                        { webhook.destinationUrl }
                      </Paragraph>

                      <Paragraph
                        className={ `col-span-1 justify-self-end self-center` }
                        variant={ `secondary` }
                      >
                        { webhook.responseCode }
                      </Paragraph>
                    </div>
                  ))
                }
              </div>
            </>
          ) }

        </When>
      </div>
    </TwoColFormRow>
  );
}
