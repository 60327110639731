import { Button } from '../../../common/Atoms/Button';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { useActionInvoicesMutation } from '../../services/api/invoiceApi/invoice';
import { useAccountingSystemName } from '../../hooks/useAccountingSystemName';

export function InvoicesHeading() {
  const currentOrg = useGetSelectedOrganisation();
  const [ actionInvoices] = useActionInvoicesMutation();

  function onSync() {
    actionInvoices({ action: `sync-all`, organisationId: currentOrg.id });
  }

  return (
    <div className={ `sm:flex sm:items-center` }>
      <div className={ `sm:flex-auto` }>
        <h1 className={ `text-xl font-semibold text-gray-900` }>{ `Recent Invoices` }</h1>
        <p className={ `mt-2 text-sm text-gray-700` }>
          { `Invoices will be automatically imported as they are created in ${useAccountingSystemName()} in real time.` }
        </p>
        <p className={ `mt-1 text-sm text-gray-700` }>
          { `Invoices are checked for applicable policies every 2 hours.` }
        </p>
      </div>
      <div className={ `mt-4 sm:mt-0 sm:ml-16 sm:flex-none` }>
        <Button
          onClick={ onSync }
          disabled={ currentOrg?.syncInProgress }
        >
          { currentOrg?.syncInProgress ? `Checking for updates...` : `Check for updates` }
        </Button>
      </div>
    </div>
  );
}
